import React, { Component } from "react";
import Navigation from "../Navigation";
import BuscarSitio from "./BuscarSitio";
import axios from "axios";
import { ToastsContainer, ToastsStore } from "react-toasts";
import { withRouter } from "react-router-dom";
import https from "https";
const instance = axios.create({
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
});
export class Equipos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lecturas: {
        firmware: "-",
        imei: 0,
        marca: "-",
        modelo: "-",
        intervalo: 0,
        acumulado: 0,
        numerotel: "0000000000",
        tipo: "1",
        _id: "-"
      },

      sitioid: ""
    };
  }
  recibirDatosP = (childData) => {
    this.setState({ lecturas: childData });
  };
  onInputChange = (e) => {
    const todoestado = this.state.lecturas;
    if (e.target.name === "firmware") {
      todoestado.firmware = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "imei") {
      todoestado.imei = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "marca") {
      todoestado.marca = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "modelo") {
      todoestado.modelo = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "numerotel") {
      todoestado.numerotel = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "tipo") {
      todoestado.tipo = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "intervalo") {
      todoestado.intervalo = e.target.value;
      this.setState({ lecturas: todoestado });
    } else if (e.target.name === "acumulado") {
      todoestado.acumulado = e.target.value;
      this.setState({ lecturas: todoestado });
    }
  };
  actualizarDatos = async (e) => {
    const updatedNote = {
      firmware: this.state.lecturas.firmware,
      imei: this.state.lecturas.imei,
      marca: this.state.lecturas.marca,
      modelo: this.state.lecturas.modelo,
      numerotel: this.state.lecturas.numerotel,
      tipo: this.state.lecturas.tipo,
      intervalo: this.state.lecturas.intervalo,
      acumulado: this.state.lecturas.acumulado
    };
    const res = await instance.put(
      process.env.REACT_APP_URL_MACHOTE+"equipos/" +
        this.state.lecturas._id,
      updatedNote
    );
    //console.log(res.data);
    if (res.data === "Equipo Actualizado") {
      ToastsStore.success("Equipo Actualizado");
    } else {
      ToastsStore.error("Fallo Actualización");
    }
  };
  async componentDidMount() {
    this.interval = setInterval(async () => {
      const tip = JSON.parse(localStorage.getItem(process.env.REACT_APP_COOKIE));
      if (tip.tipo !== "admin") {
        this.props.history.push("/");
      }
    }, 1000);
  }
  render() {
    return (
      <div id="my-page">
        <div className="main-container bg-img">
          <Navigation activo="equipos"></Navigation>
          <section className="widget-sec">
            <div className="container">
              <div className="content-area">
                <BuscarSitio recibirDatosP={this.recibirDatosP}></BuscarSitio>
                <div className="col-lg-12 col-12 ">
                  <form>
                    <div className="form-group ">
                      <label htmlFor="firmware">Firmware</label>
                      <input
                        type="text"
                        name="firmware"
                        className="form-control"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.firmware}
                      ></input>
                      <label htmlFor="imei">IMEI:</label>
                      <input
                        name="imei"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.imei}
                      ></input>
                      <label htmlFor="marca">Marca:</label>
                      <input
                        name="marca"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.marca}
                      ></input>
                      <label htmlFor="modelo">Modelo:</label>
                      <input
                        name="modelo"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.modelo}
                      ></input>
                      <label htmlFor="intervalo">
                        Intervalo (Tiempo entre cada lectura 1min a 1440min):
                      </label>
                      <input
                        name="intervalo"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.intervalo}
                      ></input>
                      <label htmlFor="acumulado">
                        Lecturas acumuladas antes de enviar (De 1 a 24
                        lecturas):
                      </label>
                      <input
                        name="acumulado"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.acumulado}
                      ></input>
                      <label htmlFor="numerotel">Número de Teléfono:</label>
                      <input
                        name="numerotel"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.numerotel}
                      ></input>
                      <label htmlFor="tipo">Tipo:</label>
                      <input
                        name="tipo"
                        className="form-control"
                        type="text"
                        onChange={this.onInputChange}
                        value={this.state.lecturas.tipo}
                      ></input>
                    </div>
                  </form>
                </div>
                <div className="col-12 flex-lg-last flex-md-first align-self-baseline">
                  <button
                    onClick={this.actualizarDatos}
                    className="btn btn-danger"
                  >
                    Actualizar
                  </button>
                  <ToastsContainer store={ToastsStore} />
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    );
  }
}

export default withRouter(Equipos);
